import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { dayjs } from '../../../../lib/dayjs';
import { Layout } from '../../../../components/layout';
import { BreadCrumb } from '../../../../components/breadcrumb';
import { isNewsTypeColor } from '../../../../lib/is-news-type-color';
import { BackGround } from '../../../../components/background';
import { Seo } from '../../../../components/seo';
import { SeasonHeader } from '../../../../components/season-header';
import * as Styles from '../../../../styles/pages/_seasonId.module.scss';
import * as CommonStyles from '../../../../styles/global/_common.module.scss';
import {Link, useIntl, navigate} from "gatsby-plugin-react-intl"
import { contentPathHelper } from '../../../../utils/contentPathHelper';
import defaultUserIcon from '../../../../images/icon_user_default.jpg'
import { useCheerio } from '../../../../hooks/use-cheerio';

const matchReduceFunc = (acc, { node: crr }) => {
  const target = acc.find(v => v.title === crr.event.title && v.date === crr.date)
  if (target) {
    target.nodes.push(crr)
  } else {
    acc.push({
      title: crr.event.title,
      date: crr.date,
      nodes: [crr]
    })
  }
  return acc
}

const SeasonSinglePage = ({ data, location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  const { microcmsSeason, microcmsEvent, allMicrocmsMatch, allMicrocmsNews, allMicrocmsSeason } = data;

  const $ = useCheerio(microcmsSeason?.rule)
  const isRuleText = useMemo(() => !!$.text(), [$])

  React.useEffect(() => {
    if (!microcmsSeason) {
      navigate(`/news/${microcmsEvent.eventId}`)
    }
  }, [microcmsSeason])

  const postDescription = microcmsEvent.description?.replace(/(<([^>]+)>)/gi, '').substr(0, 70);

  const eventLabel = microcmsSeason?.events.filter((event) => {
    return event.events.id === microcmsEvent.eventId;
  });

  const matchComponent = (node) => (
    <div>
      <p className={Styles.result__name}>{node.title}</p>
        <div className={Styles.result__list}>
          <p className={Styles.result__date}>{dayjs(node.date).format('MM月DD日(ddd)')}</p>
          {node.nodes.map(node => node.winner.length ? (
            <div className={Styles.result__box}>
              {/*青アーティストアイコン*/}
              <div className={classNames(node.winner[0] === '青' && Styles.result__winner)}>
                <div className={Styles.result__artist}>
                  {node.blue.length > 0 && node.blue.map((b, i) => (
                    <div>
                      <img key={i} src={b.image_url || b.image.url + '?w=200&q=80&fm=webp'} alt={`${b.name}${t('season.icon')}`} />
                      <p>{b.name}</p>
                    </div>
                  ))}
                  {node.blue.length === 0 && (
                    <img src={defaultUserIcon + '?w=200&q=80&fm=webp'} alt="" />
                  )}
                </div>
              </div>

              <div>
                <p className={Styles.result__match_title}>{node.title}</p>
                <div className={Styles.result__number_wrap}>
                  <p className={Styles.result__number}>
                    <strong>{node.blue_point}</strong>
                    {node.blue_examination !== null && <small>({node.blue_examination})</small>}
                  </p>
                  <p className={Styles.result__vs}>VS</p>
                  <p className={Styles.result__number}>
                    <strong>{node.red_point}</strong>
                    {node.red_examination !== null && <small>({node.red_examination})</small>}
                  </p>
                </div>
                <p className={Styles.result__btn}>
                  <Link to={`/match/${contentPathHelper(node.matchId)}/`}>{t('season.matchDetail')}</Link>
                </p>
              </div>

              {/*赤アーティストアイコン*/}
              <div className={classNames(node.winner[0] === '赤' && Styles.result__winner)}>
                <div className={Styles.result__artist}>
                  {node.red.length > 0 && node.red.map((r, i) => (
                    <div>
                      <img key={i} src={r.image_url || r.image.url + '?w=200&q=80&fm=webp'} alt={`${r.name}${t('season.icon')}`} />
                      <p>{r.name}</p>
                    </div>
                  ))}
                  {node.red.length === 0 && (
                    <img src={defaultUserIcon + '?w=200&q=80&fm=webp'} alt="" />
                  )}
                </div>
              </div>

            </div>
          ) : null)}
      </div>
    </div>
  );

  const relatedArticles = () => {
    if (!microcmsSeason?.relation.length) {
      return [];
    }

    return allMicrocmsNews.edges.filter(({ node }) => {
      let check = false;

      node.relation.forEach((relation) => {
        microcmsSeason.relation.forEach(({ id }) => {
          if (relation.id === id) {
            check = true;
          }
        });
      });

      return check;
    });
  };

  return (
    <Layout location={location}>
      {microcmsSeason && (
        <>
          <BreadCrumb
            list={[
              {
                title: 'TOP',
                link: '/',
              },
              {
                title: t('menu.seasonInformation')
              },
              {
                title: microcmsSeason?.title,
                link: '/season/' + microcmsSeason?.seasonId + '/',
              },
              {
                title: eventLabel[0].label,
                link: '/season/event/' + microcmsEvent.eventId + '/',
              },
            ]}
            currentPage={t('season.matchResult')}
          />

          <Seo title={`${t('season.matchResult')} | ${eventLabel[0].label} | ${microcmsSeason.title}${t('season.seo.title')}`} description={postDescription} />
        </>
      )}

      <BackGround>
        {microcmsSeason && <SeasonHeader currentSeason={microcmsSeason} seasonList={allMicrocmsSeason.edges} linkList={microcmsSeason.events} location={location} isSeasonRule={isRuleText} />}
        <section className={Styles.seasonId}>
          <div className={Styles.seasonId__wrapper}>
            <div className={classNames(CommonStyles.container)}>
              <h2 className={Styles.seasonId__header}>{microcmsEvent.title}</h2>

              <div className={Styles.seasonId__thumbnail}>
                <img src={microcmsEvent.image.url + '?w=1660&q=80'} alt={microcmsEvent.title} />
              </div>

              <div
                className={Styles.seasonId__description}
                dangerouslySetInnerHTML={{
                  __html: microcmsEvent.description,
                }}
              />

              <ul id="menu-list" className={Styles.seasonId__menu_list}>
                <li>
                  <Link to={`/season/event/${contentPathHelper(microcmsEvent.eventId)}#menu-list/`}>{t('season.matchSchedule')}</Link>
                </li>
                <li>
                  <Link className={Styles.seasonId__menu_list__current} to={`/season/event/${contentPathHelper(microcmsEvent.eventId)}/result#menu-list/`}>
                    {t('season.matchResult')}
                  </Link>
                </li>
                {microcmsEvent.rule && (
                  <li>
                    <Link to={`/season/event/${contentPathHelper(microcmsEvent.eventId)}/rule#menu-list/`}>{t('season.rules')}</Link>
                  </li>
                )}

                {allMicrocmsMatch.edges.length !== 0 && microcmsEvent.ranking && (
                  <li>
                    <Link to={`/season/event/${contentPathHelper(microcmsEvent.eventId)}/ranking#menu-list/`}>{t('season.ranking')}</Link>
                  </li>
                )}

                {microcmsEvent.tournament && (
                  <li>
                    <Link to={`/season/event/${contentPathHelper(microcmsEvent.eventId)}/tournament#menu-list/`}>{t('season.tournament')}</Link>
                  </li>
                )}
              </ul>

              {allMicrocmsMatch.edges.reduce(matchReduceFunc, []).map((node) => {
                return matchComponent(node);
              })}
              {allMicrocmsMatch.edges.reduce(matchReduceFunc, []).length === 0 && (
                <div>
                  {t('season.noContentsResult')}
                </div>
              )}

              <h2 className={Styles.seasonId__accent_heading}>{t('season.relatedArticles')}</h2>

              {microcmsSeason?.relation !== null ? (
                <ul className={Styles.seasonId__list}>
                  {relatedArticles()
                    .slice(0, 3)
                    .map(({ node }) => (
                      <li key={node.seasonId} className={Styles.seasonId__list_card}>
                        <Link to={`/news/${contentPathHelper(node.newsId)}/`}>
                          <img src={node.image.url} alt="" />
                          <div className={Styles.seasonId__boxwrap}>
                            <div className={Styles.seasonId__set}>
                              {dayjs(node.publishedAt).format('YYYY/MM/DD')}{' '}
                              <p className={Styles.seasonId__newscategory} style={{ backgroundColor: isNewsTypeColor(node.type[0]) }}>
                                {node.type[0]}
                              </p>
                            </div>
                            <p className={Styles.seasonId__newstitle}>{node.title}</p>
                          </div>
                        </Link>
                      </li>
                    ))}
                </ul>
              ) : (
                <p>{t('season.noArticles')}</p>
              )}
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export const query = graphql`
  query ($eventId: String) {
    microcmsEvent(eventId: { eq: $eventId }) {
      eventId
      publishedAt
      date
      in_date
      out_date
      title
      image {
        url
      }
      description
      news_relation {
        id
        publishedAt
        revisedAt
        description
      }
      about {
        fieldId
        description
        prize
        win_lose
        ohter_field {
          fieldId
          title
          content
        }
        judge_field {
          fieldId
          image {
            url
          }
          title
          name
        }
      }
      rule
      concretes {
        fieldId
        theme
      }
      abstracts {
        fieldId
        theme
      }
      tournament {
        url
      }
      ranking
    }

    allMicrocmsMatch(filter: { event: { id: { eq: $eventId } } }) {
      edges {
        node {
          matchId
          title
          date
          event {
            id
            title
            type
            news_relation {
              id
            }
          }
          red {
            id
            name
            image_url
            image {
              url
            }
          }
          red_point
          red_examination
          blue {
            id
            name
            image_url
            image {
              url
            }
          }
          blue_point
          blue_examination
          concrete
          abstract
          video_id
          publishedAt
          winner
        }
      }
    }

    microcmsSeason(events: { elemMatch: { events: { id: { eq: $eventId } } } }) {
      seasonId
      title
      publishedAt
      about
      rule
      relation {
        id
      }
      events {
        fieldId
        label
        events {
          date
          description
          id
          abstracts {
            fieldId
            theme
          }
          about {
            fieldId
            judge_field {
              fieldId
              image {
                url
              }
              name
              title
            }
            ohter_field {
              content
              fieldId
              title
            }
            prize
            win_lose
          }
          concretes {
            fieldId
            theme
          }
          image {
            url
          }
          publishedAt
          out_date
          in_date
          news_relation {
            id
          }
          tournament {
            url
          }
          title
          rule
          type
        }
      }
    }

    allMicrocmsSeason(filter: {is_private: {eq: false}}) {
      edges {
        node {
          seasonId
          title
        }
      }
    }

    allMicrocmsNews(sort: { fields: [publishedAt], order: ASC }) {
      edges {
        node {
          newsId
          image {
            url
          }
          title
          publishedAt
          type
          relation {
            id
            description
          }
        }
      }
    }
  }
`;

export default SeasonSinglePage;
